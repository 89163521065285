var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('a-layout',{staticClass:"mlayout"},[_c('a-layout',[_c('a-layout-content',{staticClass:"indexLayoutContent",staticStyle:{"padding":"0px"}},[_c('div',{staticClass:"page flex-col justify-start",staticStyle:{"height":"100%"}},[_c('top-a',{attrs:{"kind":``,"topbarhide":_vm.topbarhide,"tb":_vm.tb,"w750":_vm.w750,"topbarOpacity":_vm.topbarOpacity,"lag":_vm.lag}}),_c('div',{staticClass:"selectlan flex-col windowheight"},[_c('div',{staticClass:"selectlist"},[_vm._l((_vm.blinks[_vm.id].list),function(item,index){return [_c('div',{key:index,class:` slphoto ${_vm.id == 0 ? 'slphoto0' : ''} ${
                    _vm.id == 1 ? 'slphoto1' : ''
                  } ${_vm.id == 2 ? 'slphoto2' : ''} ${
                    _vm.id == 3 ? 'slphoto3' : ''
                  } ${_vm.id == 4 ? 'slphoto4' : ''}`,style:(`background-image: url(${item.url});`),on:{"click":() => {
                      _vm.goMindex(item.id);
                    }}},[_c('div',{staticClass:"sltitle"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"sldes"},[_vm._v(_vm._s(item.des))])])]})],2)])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }